import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { ConsentState, ConsentWithType } from 'models';
import { findCustomerConsents, findCustomerConsentTypes } from 'services';
import { mapConsentAndType } from 'utils/joining';
import type { RootState } from 'store/store';

export const defaultState: ConsentState = {
  data: [],
  isLoading: false,
  hasErrors: false
};

// Export slice
export const consentSlice = createSlice({
  name: 'consent',
  initialState: defaultState,
  reducers: {
    getConsent: (state) => {
      state.isLoading = true;
      state.hasErrors = false;
    },
    getConsentSuccess: (state, action: PayloadAction<ConsentWithType[]>) => {
      state.isLoading = false;
      state.hasErrors = false;
      state.data = action.payload;
    },
    getConsentFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
    resetConsentState: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const consentSelector = (state: RootState) => state.consent;

// Export actions
export const {
  getConsent,
  getConsentSuccess,
  getConsentFailure,
  resetConsentState
} = consentSlice.actions;

// Export reducer
export const consentReducer = consentSlice.reducer;

// Export thunk
export const fetchConsent =
  (customerId: string) => async (dispatch: Dispatch) => {
    dispatch(getConsent());
    try {
      const consent = await findCustomerConsents(customerId);
      const consentTypes = await findCustomerConsentTypes();
      const result = mapConsentAndType(consent.data, consentTypes.data);
      dispatch(getConsentSuccess(result));
    } catch {
      dispatch(getConsentFailure());
    }
  };
