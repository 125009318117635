import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { CreateWarranty } from 'models';
import {
  createWarrantyConditionByIprId,
  createWarrantyConditionSelector,
  metaSelector,
  resetCreateWarranty
} from 'store';
import { formatDate } from 'utils/date';
import { getSalesCompanyCodeOptions } from 'utils/meta';
import { showSuccessToast } from 'utils/toast';

import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';
import SelectField, { OptionType } from 'components/UI/SelectField';
import { ButtonGrid, DealerSelectorContainer } from './styled';

type Props = {
  iprId: string;
  open: boolean;
  onCreate: () => void;
  onClose: () => void;
};

export const CreateWarrantyModal: FC<Props> = ({
  iprId,
  open,
  onCreate,
  onClose
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const meta = useAppSelector(metaSelector);
  const countryDisplayNames = useDisplayNames('region');
  const { isLoading, isSuccess } = useAppSelector(
    createWarrantyConditionSelector
  );

  console.log('create', isSuccess);

  // Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm();

  // Watch
  const [companyCodeInput, consumerTypeInput] = watch([
    'companyCode',
    'consumerType'
  ]);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetCreateWarranty());
    };
  }, [dispatch]);

  // Update on success
  useEffect(() => {
    if (isSuccess) {
      onCreate();
      dispatch(resetCreateWarranty());
      showSuccessToast(
        intl.formatMessage({ id: 'modal.create_warranty_condition_success' })
      );
      onClose();
    }
  }, [dispatch, intl, isSuccess, onClose, onCreate]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const body: CreateWarranty = {
        soldDate: values.soldDate.replaceAll('-', ''),
        consumerType: values.consumerType,
        companyCode: values.companyCode,
        customerNo: values.customerNo
      };
      dispatch(createWarrantyConditionByIprId(iprId, body));
    },
    [dispatch, iprId]
  );

  // Set date
  const setDate = useCallback(
    (date: Date) => {
      setValue('soldDate', formatDate(date.getTime(), 'yyyy-MM-dd'), {
        shouldDirty: true
      });
    },
    [setValue]
  );

  // Select option
  const onSelect = useCallback(
    (name: string) => (option: OptionType) =>
      setValue(name, option.value, { shouldDirty: true }),
    [setValue]
  );

  // Sales company Countries
  const sccOptions: OptionType[] = useMemo(() => {
    return getSalesCompanyCodeOptions(
      meta.data.salesCompanyCodes.write,
      countryDisplayNames
    );
  }, [countryDisplayNames, meta]);

  const consumerTypeOptions: OptionType[] = useMemo(
    () =>
      meta.data.warrantyConsumerTypes.map(({ name }) => ({
        key: name,
        label: name,
        value: name
      })),
    [meta]
  );

  // Render data
  const content = useMemo(() => {
    if (isLoading || meta.isLoading) {
      return <Loader padding center />;
    }

    return (
      <>
        <InputField
          type="date"
          description="modal.create_warranty_condition_input_sold_date"
          register={register('soldDate', {
            required: {
              value: true,
              message: 'input.required'
            }
          })}
          setDate={setDate}
          error={errors.soldDate}
        />
        <SelectField
          name="consumerType"
          register={register('consumerType', {
            required: {
              value: true,
              message: intl.formatMessage({ id: 'input.required' })
            }
          })}
          onSelect={onSelect('consumerType')}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({
            id: 'modal.create_warranty_condition_input_customer_type'
          })}
          options={consumerTypeOptions}
          value={consumerTypeInput}
          error={errors.consumerType}
        />
        <DealerSelectorContainer>
          <SelectField
            name="companyCode"
            register={register('companyCode', {
              required: {
                value: true,
                message: intl.formatMessage({ id: 'input.required' })
              }
            })}
            onSelect={onSelect('companyCode')}
            placeholder={intl.formatMessage({ id: 'select.placeholder' })}
            label={intl.formatMessage({
              id: 'modal.register_products_input_company_code'
            })}
            options={sccOptions}
            value={companyCodeInput}
            error={errors.companyCode}
            enableSearch
          />
          <InputField
            description="modal.register_products_input_dealer_no"
            register={register('customerNo', {
              required: {
                value: true,
                message: 'input.required'
              },
              maxLength: {
                value: 20,
                message: 'input.wrong_length'
              }
            })}
            error={errors.customerNo}
          />
        </DealerSelectorContainer>
      </>
    );
  }, [
    companyCodeInput,
    consumerTypeOptions,
    consumerTypeInput,
    errors,
    intl,
    isLoading,
    meta.isLoading,
    onSelect,
    register,
    sccOptions,
    setDate
  ]);

  return (
    <EditModal
      title="modal.create_warranty_condition_title"
      open={open}
      close={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {content}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || isLoading} submit>
            <FormattedMessage id="modal.btn_create" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};
