import { ProductAttributes } from 'models/product';
import { CustomerAttributes } from 'models/customers';
import { APIError } from 'models/errors';

export enum ServiceContractFilterAttribute {
  Id = 'id',
  Status = 'status',
  ServicePlanId = 'servicePlanId',
  Type = 'type',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ServiceContractProduct = 'serviceContractProduct',
  CustomerId = 'customerId',
  IprId = 'iprId',
  SellerId = 'sellerId',
  PaymentProvider = 'paymentProvider',
  Email = 'email',
  CustomerType = 'customerType'
}

export enum ServiceContractStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  OnHold = 'ON_HOLD',
  NeverActivated = 'NEVER_ACTIVATED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

export enum ServiceContractCancellationReason {
  NotPaid = 'NOT_PAID',
  CustomerRequested = 'CUSTOMER_REQUESTED',
  NotFollowingServicePlan = 'NOT_FOLLOWING_SERVICEPLAN'
}

export enum EditServiceContractStatusField {
  Status = 'status',
  CancelReason = 'cancellationReason'
}

export enum MoveServiceContractField {
  Pnc = 'pnc',
  SerialNumber = 'serialNumber'
}

export type ServiceContractStatusUpdate = {
  status: ServiceContractStatus;
  cancellationUser?: string;
  cancellationReason?: string;
  cancellationDate?: number;
};

export type ServiceContractFilter = {
  attribute: ServiceContractFilterAttribute;
  value: string;
};

export type ServiceContractFilterObject = {
  type: string;
  startDate: string;
  endDate: string;
  paymentProvider: string;
  status: string;
  sellerId: string;
  email: string;
  customerType: string;
};

export type TermsAndConditionAttributes = {
  publicationType: string;
  publicationTitle: Record<string, string>;
  region: Record<string, string>;
  publicationId: string;
  created: number;
  updated: number;
  binaryLastModified: number;
  languages: string[];
  publishedBy: Record<string, any>;
  format: string;
  link: string;
  sizeBytes: number;
  metaData: Record<string, any>;
  sections: any[];
  documentArticleNumber: string;
  originalFilename: string;
  filename: string;
  intendedCountries: string[];
  intendedCountriesType: string;
  checksum: string;
};

export type TermsAndConditions = {
  id: string;
  type: string;
  attributes: TermsAndConditionAttributes;
};

export type SalesOrderAttributes = {
  totalPriceInclVAT: number;
  totalPriceExclVAT: number;
  monthlyPriceInclVAT: number;
  monthlyPriceExclVAT: number;
  currency: string;
  paymentMethod: string;
  paymentProvider: string;
  paymentReference: string;
  sellerIdType: string;
  sellerId: string;
  date: number;
};

export type SalesOrder = {
  id: string;
  type: string;
  attributes: SalesOrderAttributes;
};

export type ServiceContractAttributes = {
  id: string;
  type: string;
  iprId: string;
  salesOrder: SalesOrderAttributes;
  serviceContractProduct: string;
  servicePlanId: string;
  status: string;
  startDate: number;
  endDate: number;
  customerId?: string;
  termsAndConditions: TermsAndConditionAttributes[];
};

export type ServiceContract = {
  id: string;
  type: string;
  attributes: ServiceContractAttributes;
};

export type ServiceContractSuccess = {
  response: ServiceContract[];
  filters: ServiceContractFilter[] | null;
};

export type ServiceContractProduct = {
  data: ProductAttributes;
  type: string;
};

export type ServiceContractResponse = {
  id: string;
  type: string;
  attributes: {
    customer: CustomerAttributes | null;
    product: ProductAttributes;
    serviceContract: ServiceContractAttributes;
    marketName: string;
  };
};

export type ServiceContractList = {
  data: ServiceContract[] | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
  filters: {
    active: boolean;
    data: ServiceContractFilter[] | null;
  };
  exports: {
    data: string | null;
    errors: APIError[];
    isLoading: boolean;
  };
};

export type ServiceContractDetails = {
  data: ServiceContractResponse | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ServiceContractUpdateStatus = {
  data: ServiceContractStatus | null;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type SendMail = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type MoveServiceContract = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: APIError | null;
};

export type ServiceContractState = {
  list: ServiceContractList;
  details: ServiceContractDetails;
  status: ServiceContractUpdateStatus;
  sendMail: SendMail;
  move: MoveServiceContract;
};

export type ServiceContractExportResponse = {
  data: string;
  errors: APIError[];
};
