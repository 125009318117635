import * as config from 'config';
import {
  ProductFilter,
  ProductDetailsResponse,
  RegisterProductsCustomer,
  ServiceRecord,
  ProductFactoryPinResponse,
  WarrantyResponse,
  Warranty,
  ServicePlan,
  ServicePlanResponse,
  ServicePlanParams,
  CreateWarranty,
  ProductDetails,
  ProductTreeResponse,
  ProductTreeAttributes,
  ProductResponse,
  ProductAttributes
} from 'models';
import { apiFetch, createParams } from 'utils/fetch';
import { getServiceRecords } from 'utils/product';

export async function findProductInfo(
  filters: ProductFilter[],
  languageCode = 'en'
): Promise<ProductAttributes> {
  const url = createParams(`${config.backendApi}/product/${languageCode}`, [
    ...filters,
    { attribute: 'imageResolution', value: '300x300' }
  ]);

  const json = await apiFetch<ProductResponse>(url);

  return json.data.attributes;
}

export async function findProductRegistrations(
  filters: ProductFilter[],
  languageCode = 'en'
): Promise<ProductDetails> {
  const url = createParams(`${config.backendApi}/product-registrations`, [
    ...filters,
    { attribute: 'imageResolution', value: '180x180' },
    { attribute: 'languageCode', value: languageCode }
  ]);

  const json = await apiFetch<ProductDetailsResponse>(url);

  return json.data.attributes;
}

export async function findProductRegistrationsByIprId(
  iprId: string,
  languageCode = 'en'
): Promise<ProductDetails> {
  const params = `iprId=${iprId}&imageResolution=500x500&languageCode=${languageCode}`;
  const url = `${config.backendApi}/product-registrations?${params}`;

  const json = await apiFetch<ProductDetailsResponse>(url);

  return json.data.attributes;
}

export async function findProductTreeByIprId(
  iprId: string
): Promise<ProductTreeAttributes> {
  const url = `${config.backendApi}/product/${iprId}/product-tree`;

  const json = await apiFetch<ProductTreeResponse>(url);

  return json.data.attributes;
}

export async function registerProductsCustomer(
  data: RegisterProductsCustomer
): Promise<any> {
  const { customerId, product, dealer, soldDate } = data;

  // Url
  const url = `${config.backendApi}/product-registrations/${customerId}`;

  return apiFetch<any>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'product-registration',
        attributes: {
          product,
          dealer,
          soldDate
        }
      }
    })
  });
}

export async function getWarrantyConditionByIprId(
  iprId: string
): Promise<Warranty> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;

  const res = await apiFetch<WarrantyResponse>(url);

  return res.data;
}

export async function createWarrantyConditionByIprId(
  iprId: string,
  createWarranty: CreateWarranty
): Promise<Warranty> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;
  const { companyCode, consumerType, customerNo, soldDate } = createWarranty;

  const res = await apiFetch<WarrantyResponse>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'operation',
        attributes: {
          name: 'addWarrantyCondition',
          warrantycondition: {
            soldDate,
            consumerType,
            companyCode,
            customerNo
          }
        }
      }
    })
  });

  return res.data;
}

export async function removeWarrantyConditionByIprId(
  iprId: string
): Promise<void> {
  const url = `${config.backendApi}/product/${iprId}/warranty-condition`;

  await apiFetch<void>(url, {
    method: 'DELETE'
  });
}

export async function findServiceRecordsByIprId(
  iprId: string
): Promise<ServiceRecord[]> {
  const url = `${config.backendApi}/product/${iprId}/service-records?include=performedtask`;

  const { data, included } = await apiFetch<any>(url);

  return getServiceRecords(data, included);
}

export async function findServicePlans(
  params: ServicePlanParams
): Promise<ServicePlan> {
  const { iprId, pnc } = params;

  const queryParams = iprId ? `iprId=${iprId.trim()}` : `pnc=${pnc?.trim()}`;
  const url = `${config.backendApi}/service-plans?${queryParams}`;

  const response = await apiFetch<ServicePlanResponse>(url);

  return response.data;
}

export async function getFactoryPinByIprId(
  iprId: string
): Promise<ProductFactoryPinResponse> {
  const url = `${config.backendApi}/product/${iprId}/factorypin`;

  const response = await apiFetch<any>(url);

  return response;
}
